.App{
    background-color: #18212c;
    color: #ffffff;
}

.Heading{
    color: #daac5f!important;
}

.Testimonial{
    min-height: 250px;
}

.Center{
    text-align: center;
}

.Into{
    padding-top: 30px;
}

.Padding{
    padding-top: 10px;
    padding-bottom: 10px;
}

.Email{
    color: #daac5f;
}

.Phone{
    color: #daac5f;
}

.Annotation{
    padding-top: 0px;
    padding-bottom: 0px;
    padding: 20px;
    font-style: italic;
    text-align: center;
}

.Link{
    color: #daac5f;
}